import React from 'react';

import Meta from '../../components/meta';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../../components/contents';

import SidebarRecruit from '../../components/sidebarRecruit';

const Culture = () =>{



    return(
        <>

            <Meta
                title="成長支援"
                description="働きやすい環境でより良いサービスをお客様に"
            />

            <Contents>

                <div className="c-sub-bg is-culture">

                    <div className="c-sub-header">
                        <em className="c-sub-header--eng u-josef">
                        COMPANY<br />CULTURE
                        </em>

                        <h1 className="c-sub-header--title">
                        働きやすい環境でより良いサービスをお客様に
                        </h1>
                    </div>

                </div>



                <article className="p-recruit-jobs">

                    <section className="p-recruit-jobs-section">

<div className="p-recruit-section-box is-detail">
    <div className="p-recruit-job">
        <p className="p-recruit-job--text">
        <span className="is-sub">アクロスソリューションズで</span>働くしくみ
        </p>
    </div>

    <div className="p-recruit-cat">
        <p className="p-recruit-cat--text">
        WORK SOLUTION
        </p>
    </div>

    <div className="p-recruit-cl-list">

        {/* <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            <span className="is-caption">カフェチケット</span>
            就業時間中フリータイム
            </p>

            <StaticImage
                src="../../images/image/p_recruit_solution_1.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            エンジニアなどの内勤者は営業と異なり、昼食以外の休憩が取りにくく、仕事に集中しにくい環境でした。勤務時間内に最大1時間（昼食休憩以外）の休憩を取ることができます。会社が準備したカフェチケットで最寄りの喫茶店やカフェでリフレッシュできます。
            </p>
            <p className="p-recruit-cl--text is-attr">
            ※最大8回/月
            </p>
        </div>

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            出社時ランチチケット
            </p>

            <StaticImage
                src="../../images/image/p_recruit_solution_2.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            我々の業界においては、コロナ禍によりテレワーク勤務が主流となりつつあります。コロナ終息後は月の50％<span className="is-attr">※</span>をテレワーク勤務可能であり、出社時の働きやすさを目指す為、最大2回/月のランチチケットの利用が可能です。
            </p>
            <p className="p-recruit-cl--text is-attr">
            ※%は状況等により変更する場合があります。
            </p>
        </div> */}

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            フリードリンク・置き菓子
            </p>

            <StaticImage
                src="../../images/image/p_recruit_solution_3.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            このしくみは内勤者にとって働きやすい環境のひとつです。お客様対応中や会議中など、まわりや他人に迷惑が掛からないタイミングであれば、いつでも利用することができます。
            </p>
        </div>

    </div>
</div>

<div className="p-recruit-section-box is-detail">
    <div className="p-recruit-job">
        <p className="p-recruit-job--text">
        働く環境
        </p>
    </div>

    <div className="p-recruit-cat">
        <p className="p-recruit-cat--text">
        ENVIRONMENT
        </p>
    </div>

    <div className="p-recruit-cl-list">

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            <span className="is-caption">テレワーク勤務</span>
            モバイルワーク
            </p>

            <StaticImage
                src="../../images/image/p_recruit_env_5.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            モバイルワークとは自宅及び会社が認めた場所において働くことができるしくみです。
            申請することなく、いつでもモバイルワークが可能です。
            </p>
        </div>

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            オフィスフリーアドレス
            </p>

            <StaticImage
                src="../../images/image/p_recruit_env_1.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            以前までは自分専用の机が決まっていました。しかしコロナ禍により出社タイミングが各自それぞれになってきました。勤務環境をフリーにし、好きな日に出社し好きな席で仕事をする環境を整えています。次の利用者のことも考慮し、利用後は備品等を消毒しましょう。
            </p>
        </div>

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            誕生日休暇
            </p>

            <StaticImage
                src="../../images/image/p_recruit_env_2.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            当社では「公私共に充実してほしい」という働き方を提唱しており、誕生日に休暇を取得可能です。
            </p>
        </div>


        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            健康診断
            </p>

            <StaticImage
                src="../../images/image/p_recruit_env_3.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            やはり体が資本です。当社もメンバーの健康診断を定期的（1回/年）に実施しており、再検査の際の休暇にも対応しています。またリフレッシュ休暇の取得も可能なため、心身共にリラックスした状態で業務に取り掛かれます。
            </p>
        </div>

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            各種予防接種支援
            </p>

            <StaticImage
                src="../../images/image/p_recruit_env_4.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            コロナワクチン接種日や副作用に応じた休暇取得も可能です。またコロナワクチンに限らず、一般的な予防接種においても会社から支援を行っています。
            </p>
        </div>

    </div>
</div>

<div className="p-recruit-section-box is-detail">
    <div className="p-recruit-job">
        <p className="p-recruit-job--text">
        成長支援
        </p>
    </div>

    <div className="p-recruit-cat">
        <p className="p-recruit-cat--text">
        SKILL UP SUPPORT
        </p>
    </div>

    <div className="p-recruit-cl-list">

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            書籍購入補助
            </p>

            <StaticImage
                src="../../images/image/p_recruit_skill_1.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            業務や自身の啓発に関すること、とにかく「成長」に関わる書籍あれば会社は喜んで支援します。申請制ですが購入補助額の上限はありません。個人が成長することで会社も成長することができます。
            </p>
        </div>

        <div className="p-recruit-cl">
            <p className="p-recruit-cl--title">
            資格取得補助
            </p>

            <StaticImage
                src="../../images/image/p_recruit_skill_2.png"
                alt=""
                placeholder="blurred"
                quality="100"
                className="p-recruit-cl--image"
            />

            <p className="p-recruit-cl--text">
            書籍購入と同様、「成長」に関することへの支援は惜しみません。研修受講料・受験料・合格一時金だけではなく、受講受験に必要な旅費経費等の支援も行います。また受験したい資格があれば申請も可能です。
            </p>
        </div>

    </div>

</div>

                    </section>

                </article>



                <SidebarRecruit />



            </Contents>

        </>
    )
}

export default Culture;


